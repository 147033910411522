import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/site-wide/layout/layout";
import Divider from "../components/common/divider";

export default () => {
  return (
    <>
      <Layout title="Защита на личните данни">
        <Container className="mt-5 pt-5">
          <div className="text-center mb-5">
            <h1>Защита на личните данни</h1>
            <Divider />
          </div>

          <div className="mb-5">
            <p>
              <span style={{ fontWeight: 400 }}>
                ИНФОРМАЦИЯ ОТНОСНО ПОЛИТИКАТА ПО ЗАЩИТА НА ЛИЧНИТЕ ДАННИ,
                ОБРАБОТВАНИ в Локсмит Експерт ООД, във връзка с чл. 13 и чл. 14 на
                Регламент ЕС 2016/679
              </span>
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h3>
              <strong>1. Администратор на личните данни</strong>
            </h3>
            <p>
              <strong>
                <em>Локсмит Експерт ООД</em>
              </strong>
              <em>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  с ЕИК: 206195872, адрес:{" "}
                </span>
              </em>
              <span style={{ fontWeight: 400 }}>
                гр. София, жк. Стрелбище, бл. 4{" "}
              </span>
              <em>
                <span style={{ fontWeight: 400 }}>
                  &nbsp;&nbsp;ел. поща: arno.bistro@gmail.com,&nbsp;
                  тел./факс:&nbsp; +359 (0)2 417 9457 („Администратор на личните
                  данни“)&nbsp; обработва лични данни на физически лица
                  („субекти на данни“) в съответствие с Регламент (ЕС) 2016/679
                  (Общ регламент относно защитата на данните) (GDPR) и Закона за
                  защита на личните данни.&nbsp;
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  Съгласно Общия регламент, лични данни е всяка информация,
                  която се отнася до физическо лице и чрез която то може да бъде
                  пряко или непряко идентифицирано. Обработване на лични данни е
                  всяка операция или съвкупност от операции, които могат да се
                  извършат по отношение на личните данни с автоматични или други
                  средства.
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>&nbsp;</span>
              </em>
            </p>
            <h3>
              <strong>
                2. Физически лица, чиито лични данни се обработват от
                дружеството
              </strong>
            </h3>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  Във връзка с предоставяните от{" "}
                </span>
              </em>
              <strong>
                <em>Локсмит Експерт ООД</em>
              </strong>{" "}
              <strong>
                <em>&nbsp;</em>
              </strong>
              <em>
                <span style={{ fontWeight: 400 }}>
                  обработва лични данни относно следните субекти на данни:
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  - Физически лица, контрагенти или потенциални контрагенти на
                  дружеството - клиенти и доставчици на стоки и услуги,
                  съответно техни служители.
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>&nbsp;</span>
              </em>
            </p>
            <h3>
              <strong>3. Цели на обработване&nbsp;</strong>
            </h3>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  Дружеството обработва лични данни на своите клиенти,
                  включително на техни служители, за следните цели:
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  (а) Предоставяне на технически и дигитални услуги в изпълнение
                  на договор;
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  (б) Защита на легитимни интереси на дружеството, включително:
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  - Осигуряване на нормалното функциониране, поддръжката и
                  сигурността на системите на дружеството;
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  - Осъществяване на комуникация с клиентите, включително по
                  електронен път;
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  - Осъществяване и закрила на правата и законните интереси на
                  дружеството, включително и по съдебен ред.
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  (в) Изпълнение на законовите задължения на дружеството и
                  изпълнение на разпореждания компетентни държавни или съдебни
                  органи.
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>&nbsp;</span>
              </em>
            </p>
            <h3>
              <strong>4. Правни основания за обработване&nbsp;</strong>
            </h3>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  Дружеството обработва лични данни единствено при наличие на
                  някое от алтернативните правни основания по Общия регламент и
                  по-специално:
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  (а) Изпълнение на договор, включително преддоговорните
                  отношения преди сключването му;
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  (б) Законови задължения, приложими спрямо дружеството;
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  (в) Легитимните интереси на дружеството, доколкото те имат
                  преимущество над интересите или основните права и свободи на
                  субектите на данни;
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  (г) Свободно изразено, конкретно, информирано и недвусмислено
                  съгласие на субекта на данните. Вече даденото съгласие може да
                  бъде оттеглено от лицето по всяко време по същия начин, по
                  който е било предоставено.
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>&nbsp;</span>
              </em>
            </p>
            <h3>
              <strong>
                5. Евентуални последствия, ако не бъдат предоставени личните
                данни
              </strong>
            </h3>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  В случай, че клиентът не предостави изискуемата информация,
                  включително необходимите лични данни, дружеството няма да може
                  да сключи договор с него, съответно няма да може да предостави
                  исканата услуга.&nbsp;
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>&nbsp;</span>
              </em>
            </p>
            <h3>
              <strong>
                6. На кого се предават или разкриват личните данни
              </strong>
            </h3>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  Личните данни на клиентите на дружеството се предоставят на:
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  &nbsp;- Търговски дружества, предоставящи счетоводни услуги и
                  информационно поддържане на IT системите на дружеството, в
                  качеството на обработващи лични данни;
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  - Други компетентни публични органи в изпълнение на
                  задължение, предвидено в закона.&nbsp;
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>&nbsp;</span>
              </em>
            </p>
            <h3>
              <strong>7. Срок на съхранение на личните данни</strong>
            </h3>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  Личните данни на контрагентите на дружеството се съхраняват за
                  срок от 5 години от приключването на съответния договор в
                  съответствие с общата погасителна давност по Закона за
                  задълженията и договорите.
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  Личните данни, съдържащи се в счетоводни документи, се
                  съхраняват в сроковете по чл. 12 от Закона за счетоводството.
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>&nbsp;</span>
              </em>
            </p>
            <h3>
              <strong>8. Сигурност на личните данни</strong>
            </h3>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  Дружеството прилага всички подходящи технически и
                  организационни мерки за гарантиране сигурността на личните
                  данни, включително поемане на изрично задължение от
                  служителите за конфиденциалност.
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>&nbsp;</span>
              </em>
            </p>
            <h3>
              <strong>9. Права като субектите на данните</strong>
            </h3>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  Всяко физическо лице, чиито данни се обработват от
                  дружеството, има следните права:
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  - право на достъп до личните му данни, включително да получи
                  копие от тях;
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  - право на коригиране или допълване на неточни или непълни
                  лични данни;
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  - право на изтриване на лични данни, които се обработват без
                  правно основание;
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  - право на ограничаване на обработването - при наличие на
                  правен спор между дружеството и лицето до неговото решаване
                  или за установяването, упражняването или защитата на правни
                  претенции;
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  - право на възражение - по всяко време и на основания,
                  свързани с конкретната ситуация на лицето, при условие, че не
                  съществуват убедителни законови основания за обработването,
                  които имат предимство пред интересите, правата и свободите на
                  субекта на данни, или съдебен процес.
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  В съответствие със Закона за защита на личните данни,
                  посочените по-горе права могат да се упражнят чрез подаване на
                  писмено заявление на място в офиса на{" "}
                </span>
              </em>
              <strong>
                <em>Локсмит Експерт ООД</em>
              </strong>
              <em>
                <span style={{ fontWeight: 400 }}>
                  . Заявление може да бъде отправено и по електронен път по реда
                  на Закона за електронния документ и електронния подпис.
                  Заявлението се отправя лично от субекта на данни или от
                  изрично упълномощено от него лице.
                </span>
              </em>
            </p>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>&nbsp;</span>
              </em>
            </p>
            <h3>
              <strong>10. Защита на правата на субектите на данните</strong>
            </h3>
            <p>
              <em>
                <span style={{ fontWeight: 400 }}>
                  В съответствие със Закона за защита на личните данни и
                  Регламент (ЕС) 2016/679 (Общ регламент за защита на данните),
                  всяко физическо лице, което счита, че правото му на защита на
                  личните му данни е нарушено, може да подаде жалба до Комисията
                  за защита на личните данни на адрес: гр. София 1592, бул.
                  „Проф. Цветан Лазаров” № 2, интернет страница:
                </span>
              </em>
              <a href="https://www.cpdp.bg/">
                <em>
                  <span style={{ fontWeight: 400 }}> https://www.cpdp.bg/</span>
                </em>
              </a>
              <em>
                <span style={{ fontWeight: 400 }}>.</span>
              </em>
            </p>
          </div>
        </Container>
      </Layout>
    </>
  );
};
